import moment, { duration } from 'moment';

const OFFSET_DAYS = 56;
const DATE_FORMAT = 'YYYY-MM';

export const formatFlightTime = (flightDuration: number) =>
  duration(flightDuration, 'seconds').format('h[h] m[m]', { trim: 'all' });

// if current date is within 56 days of next October return next May
export const getDefaultSearchMonth = (now?: string) => {
  const currentDate = moment(now);
  const october = moment().set({ month: 9, date: 1 });
  const may = moment().set({ month: 4, date: 1 });
  const daysTillOct = october.diff(currentDate, 'days');
  const daysTillMay = may.diff(currentDate, 'days');

  // within 56 days of October 1st, or after it - return next May
  if (daysTillOct <= OFFSET_DAYS) {
    return may.add(1, 'year').format(DATE_FORMAT);
  }
  // Jan 1st up to 56 days before May 1st, return this May
  if (daysTillMay > 0 && daysTillMay < daysTillOct && daysTillMay > OFFSET_DAYS) {
    return may.format(DATE_FORMAT);
  }
  // within 56 days of May first but more than 56 days of October 1st - return October
  return october.format(DATE_FORMAT);
};

export const formatMonthYear = ({
  month,
  year
}: {
  month?: string | number;
  year?: string | number;
}): string | undefined =>
  month && year ? `${Number(year)}-${Number(month) < 10 ? '0' : ''}${Number(month)}` : undefined;

export const isValidSearchDate = ({
  day,
  month,
  year,
  wholeMonth
}: {
  day?: number | string;
  month: number | string;
  year: number | string;
  wholeMonth?: boolean;
}) => {
  const searchDate = moment()
    .year(Number(year))
    .month(Number(month) - 1);
  if (!wholeMonth && day) {
    searchDate.date(Number(day));
  }
  return searchDate.isSameOrAfter(moment(), 'day');
};

export const isValidSearchDateString = (date?: string) => {
  if (!date) return false;
  const [year, month, day] = date.split('-');
  return isValidSearchDate({
    year,
    month,
    day: day || '',
    wholeMonth: !!day
  });
};

export const getTimeOffsetDisplay = (timeOffset: string) => {
  const now = moment();
  const expires = moment(timeOffset);
  const days = expires.diff(now, 'days');
  const toTheDay = expires.subtract(days, 'days');
  const hours = toTheDay.diff(now, 'hours');
  const toTheHour = toTheDay.subtract(hours, 'hours');
  const minutes = toTheHour.diff(now, 'minutes');
  const toTheMinute = toTheHour.subtract(minutes, 'minutes');
  const seconds = toTheMinute.diff(now, 'seconds');

  return `${days > 0 ? `${days} day${pluralise(days)}, ` : ''}${
    hours > 0 ? `${hours} hour${pluralise(hours)}, ` : ''
  } ${minutes > 0 ? `${minutes} minute${pluralise(minutes)} and ` : ''}${Math.max(seconds, 0)} second${pluralise(
    seconds
  )}`;
};

export const pluralise = (value: number) => {
  return `${value !== 1 ? 's' : ''}`;
};
