import { Page } from '@model/common';
import { call, put, putResolve, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { TripTypes } from '@model/common';
import { Basket, BasketResponse, Transfer } from '@model/iceberg/booking';
import { FlightApi } from '@model/iceberg/service/booking/flight-api';
import { LuggageApi } from '@model/iceberg/service/booking/luggage-api';
import { TransferApi } from '@model/iceberg/service/booking/transfer-api';
import { SearchUrlBuilder } from '@model/search/fit/search-params/search-url-builder';
import { performNavigate } from '@state/app';
import Router from 'next/router';
import {
  BasketActions,
  navigate,
  receiveAddToBasketFailure,
  receiveAddToBasketSuccess,
  receiveBasketFailure,
  receiveBasketSuccess,
  receiveAddToBasketNoop
} from './basketActions';
import { SupplementsApi } from '@model/iceberg/service/booking/supplements-api';
import { SpecialRequestsApi } from '@model/iceberg/service/booking/special-requests-api';
import { getBookingPageFromUrl } from '@model/common/pages/get-booking-page-from-url';
import { RoomsApi } from '@model/iceberg/service/booking/rooms-api';
import { RequestType, setLoading } from '@state/app/loading/loadingOperations';
import { BookingApi, DestinationLinkBuilder, LinkItem } from '@model/iceberg';
import { getDealFinderResultsToken } from '@state/deal-finder-results/dealFinderResultsSelectors';
import { getToursSearchToken } from '@state/search-results/tours';
import { getBasket } from '@state/basket/basketSelectors';
import { DealFinderState } from '@model/state';
import { getDealFinderLastSearchState } from '@state/deal-finder-last-searched/dealFinderLastSearchedSelectors';
import { closeModal, ModalType, showModal } from '@state/modal/modalOperations';
import { getLuggage, getTransfers } from '@state/extras/extraSelectors';
import { getDealFinderState } from '@state/deal-finder/dealFinderSelectors';
import { Transfers } from '@model/iceberg/common/transfers';
import { LuggageExtra } from '@model/iceberg/booking/extras';
import { getDealFinderUrl } from '@util/deal-finder';
import {
  getTourSearchUrl,
  getTourProductUrl,
  getTourExtrasUrl,
  getTourGuestsUrl,
  getTourPaymentUrl,
  isTourTripType
} from '@util/tours';
import { getErrorCode } from '@util/error';

export function* onNavigate() {
  yield takeEvery(BasketActions.NAVIGATE, handleNavigate);
}

export function* onNavigateBack() {
  yield takeEvery(BasketActions.NAVIGATE_BACK, handleOnNavigateBack);
}

export function* onFetchBasket() {
  yield takeLatest(BasketActions.FETCH_BASKET, handleFetchBasket);
}

export function* onAddRoomsToBasketAndNavigate() {
  yield takeEvery(BasketActions.ADD_ROOMS_TO_BASKET_AND_NAVIGATE, handleAddRoomsToBasketAndNavigate);
}

export function* onAddRoomsToBasket() {
  yield takeEvery(BasketActions.ADD_ROOMS_TO_BASKET, handleAddRoomsToBasket);
}

export function* onAddFlightToBasket() {
  yield takeEvery(BasketActions.ADD_FLIGHT_TO_BASKET, handleAddFlightToBasket);
}

export function* onPreBookFlightToBasket() {
  yield takeEvery(BasketActions.PREBOOK_FLIGHT_TO_BASKET, handlePreBookFlightToBasket);
}

export function* onConfirmExtras() {
  yield takeEvery(BasketActions.CONFIRM_EXTRAS, handleConfirmExtras);
}

export function* onAddTransfersToBasket() {
  yield takeEvery(BasketActions.ADD_TRANSFERS_TO_BASKET, handleAddTransfersToBasket);
}

export function* onAddLuggageToBasket() {
  yield takeEvery(BasketActions.ADD_LUGGAGE_TO_BASKET, handleAddLuggageToBasket);
}

export function* onAddSpecialRequestsToBasket() {
  yield takeEvery(BasketActions.ADD_SPECIAL_REQUESTS_TO_BASKET, handleAddSpecialRequestsToBasket);
}

export function* onAddSupplementsToBasket() {
  yield takeEvery(BasketActions.ADD_SUPPLEMENTS_TO_BASKET, handleAddSupplementsToBasket);
}

export function* handleNavigate({ payload: { page, section } }: any) {
  const isTourJourney = Router.query?.tripType && isTourTripType(Router.query?.tripType as TripTypes);
  const token: string = yield select(isTourJourney ? getToursSearchToken : getDealFinderResultsToken);
  const routerSearchToken = Router.query?.searchToken;
  const searchToken: string | undefined = Array.isArray(routerSearchToken) ? routerSearchToken[0] : routerSearchToken;
  const basket: Basket = yield select(getBasket);
  const path = basket.hotel?.place.hotel?.path;
  let url: string;

  if (page === Page.PRODUCT && path) {
    const { airports, duration, occupancy, date, searchType, preferredBoardBasis } = yield select(getDealFinderState);
    url = getDealFinderUrl({
      token: token || searchToken || '',
      path,
      page: Page.PRODUCT,
      from: airports,
      duration,
      occupancy,
      date,
      searchType,
      section,
      preferredBoardBasis
    });
  } else if (page === Page.GUESTS && isTourJourney) {
    url = getTourGuestsUrl({ ...Router.query, searchToken });
  } else if (page === Page.PAYMENT && isTourJourney) {
    url = getTourPaymentUrl({ ...Router.query, searchToken });
  } else {
    url = new SearchUrlBuilder()
      .withSearchToken(token || searchToken || '')
      .withProductPath(path)
      .withHash(section)
      .build(page);
  }
  yield put(performNavigate(url));
}

export function* handleAddRoomsToBasket({ token, rooms }: any) {
  try {
    const api: RoomsApi = new RoomsApi();
    const response: BasketResponse = yield api.put(token, rooms);
    yield put(receiveAddToBasketSuccess(response));
  } catch (e: any) {
    yield putResolve(receiveAddToBasketFailure(getErrorCode(e)));
  }
}

export function* handleAddRoomsToBasketAndNavigate({ token, rooms }: any) {
  try {
    const api: RoomsApi = new RoomsApi();
    const response: BasketResponse = yield api.put(token, rooms);
    yield put(receiveAddToBasketSuccess(response));
    yield put(navigate(Page.PRODUCT));
  } catch (e: any) {
    yield putResolve(receiveAddToBasketFailure(getErrorCode(e)));
  }
}

export function* handleAddFlightToBasket({ token, flightToken }: any) {
  if (flightToken) {
    try {
      const api: FlightApi = new FlightApi();
      const response: BasketResponse = yield api.put(token, flightToken, false);
      yield put(receiveAddToBasketSuccess(response));
    } catch (e: any) {
      yield putResolve(receiveAddToBasketFailure(getErrorCode(e)));
    }
  }
}

export function* handlePreBookFlightToBasket({ token, flightToken }: any) {
  if (flightToken) {
    try {
      const api: FlightApi = new FlightApi();
      const response: BasketResponse = yield api.put(token, flightToken, true);
      yield put(receiveAddToBasketSuccess(response));
      yield put(setLoading(false, RequestType.BASKET));
      yield put(navigate(Page.REVIEW));
    } catch (e: any) {
      yield putResolve(receiveAddToBasketFailure(getErrorCode(e)));
      yield put(setLoading(false, RequestType.BASKET));
    }
  }
}

export function* handleConfirmExtras() {
  const basket: Basket = yield select(getBasket);
  const luggageOptions: Array<LuggageExtra> = yield select(getLuggage);
  const transferOptions: Array<Transfer> = yield select(getTransfers);
  const hasTransfer =
    Array.isArray(basket.transfer) || (!!basket.transfer && basket.transfer.vehicle !== Transfers.NO_TRANSFER);
  const hasLuggage = !!basket.luggage;

  if ((!!luggageOptions.length && !hasLuggage) || (transferOptions.length > 1 && !hasTransfer)) {
    yield put(showModal(ModalType.TRANSFER_LUGGAGE_MODAL));
  } else {
    yield put(closeModal());
    yield put(navigate(Page.GUESTS));
  }
}

export function* handleAddTransfersToBasket({ token, transfer }: any) {
  try {
    const transferApi: TransferApi = new TransferApi();
    const api: BookingApi = new BookingApi();
    yield transferApi.put(token, transfer);
    const response: BasketResponse = yield api.get(token);
    yield put(receiveAddToBasketSuccess(response));
  } catch (e: any) {
    yield putResolve(receiveAddToBasketFailure(getErrorCode(e)));
  }
}

export function* handleAddLuggageToBasket({ token, luggageToken, count }: any) {
  try {
    const luggageApi: LuggageApi = new LuggageApi();
    const api: BookingApi = new BookingApi();
    yield put(setLoading(true, RequestType.LUGGAGE));
    yield luggageApi.put(token, luggageToken, count);
    const response: BasketResponse = yield api.get(token);
    yield put(receiveAddToBasketSuccess(response));
    yield put(setLoading(false, RequestType.LUGGAGE));
  } catch (e: any) {
    const {
      response: { status }
    } = e;
    if (status === 429) {
      yield putResolve(receiveAddToBasketNoop());
    } else {
      yield putResolve(receiveAddToBasketFailure(getErrorCode(e)));
      yield put(setLoading(false, RequestType.LUGGAGE));
    }
  }
}

export function* handleAddSpecialRequestsToBasket({ token, specialRequests }: any) {
  try {
    const specialRequestsApi: SpecialRequestsApi = new SpecialRequestsApi();
    const response: BasketResponse = yield specialRequestsApi.put(token, specialRequests);
    yield put(receiveAddToBasketSuccess(response));
  } catch (e: any) {
    const {
      response: { status }
    } = e;
    if (status === 429) {
      yield putResolve(receiveAddToBasketNoop());
    } else {
      yield putResolve(receiveAddToBasketFailure(getErrorCode(e)));
    }
  }
}

export function* handleAddSupplementsToBasket({ token, supplements }: any) {
  try {
    const supplementsApi: SupplementsApi = new SupplementsApi();
    const response: BasketResponse = yield supplementsApi.put(token, supplements);
    yield put(receiveAddToBasketSuccess(response));
  } catch (e: any) {
    yield putResolve(receiveAddToBasketFailure(getErrorCode(e)));
  }
}

export function* handleFetchBasket({ searchToken, prePay }: any) {
  try {
    const api: BookingApi = new BookingApi();
    const response: BasketResponse = yield api.get(searchToken, prePay);
    yield put(receiveBasketSuccess(response));
  } catch (e: any) {
    yield put(receiveBasketFailure(getErrorCode(e)));
  }
}

export function* handleOnNavigateBack({ url }: any) {
  const page: Page | undefined = getBookingPageFromUrl(url);
  const token: string = yield select(getDealFinderResultsToken);
  const { searchToken: routerSearchToken, productPath = '' } = Router.query;
  const searchToken: string | undefined = Array.isArray(routerSearchToken) ? routerSearchToken[0] : routerSearchToken;
  const urlToken = token || searchToken || '';
  const basket: Basket = yield select(getBasket);
  const lastSearched: DealFinderState = yield select(getDealFinderLastSearchState);

  if (page) {
    if (page === Page.SEARCH) {
      const href: string = new SearchUrlBuilder().withSearchToken(urlToken).build(page);
      yield call(Router.push, href);
    } else if (page === Page.TOURS_EXTRAS) {
      yield call(Router.push, getTourExtrasUrl({ ...Router.query, searchToken }));
    } else {
      const queryPath = Array.isArray(productPath) ? productPath[0] : productPath || '';
      const path = basket.hotel?.place.hotel?.path || queryPath;
      const href: string = new SearchUrlBuilder().withSearchToken(urlToken).withProductPath(path).build(page);
      yield call(Router.push, href);
    }
  } else if (url === Page.DESTINATION) {
    const lastDestination: string = lastSearched.destinations[0]?.path || '';
    if (lastDestination) {
      const hotelUrl: LinkItem = new DestinationLinkBuilder().getDestinationLink(lastSearched.destinations[0].path);
      yield call(Router.push, hotelUrl.href, hotelUrl.as);
    } else {
      yield call(Router.push, Page.HOME);
    }
  } else if (url === Page.DESTINATION_HOTELS) {
    const lastDestination: string = lastSearched.destinations[0]?.path || '';
    if (lastDestination) {
      const hotelsUrl: LinkItem = new DestinationLinkBuilder().getDestinationHotelsLink(
        lastSearched.destinations[0].path
      );
      yield call(Router.push, hotelsUrl.href, hotelsUrl.as);
    } else {
      yield call(Router.push, Page.HOME);
    }
  } else if (url === Page.TOURS_SEARCH) {
    yield call(Router.push, getTourSearchUrl({ ...Router.query, searchToken }));
  } else if (url === Page.TOURS_PRODUCT) {
    yield call(Router.push, getTourProductUrl({ ...Router.query, searchToken }));
  } else {
    yield call(Router.push, url);
  }
}
